require('./bootstrap');

require('magnific-popup/dist/jquery.magnific-popup')
require('slick-carousel/slick/slick')
require('slick-animation/slick-animation')
require('waypoints/lib/jquery.waypoints')
require('counterup/jquery.counterup.min')
require('@fancyapps/ui/dist/fancybox.umd')
require('masonry-layout/dist/masonry.pkgd.min')

$(document).ready(function() {
    /* -------------------- Preloader -------------------- */
    let preloader = $("#preloader")
    preloader.fadeOut(0)

    $(document).on('click', '.cancel-preloader a', function (e) {
        e.preventDefault();
        $("#preloader").fadeOut(2000);
    });

    /* -------------------- Navbar Fix -------------------- */
    $(document).on('click', '.navbar-area .navbar-nav li.menu-item-has-children>a', function (e) {
        e.preventDefault();
    })

    /* -------------------- Menu Toggle -------------------- */
    let menuToggle = $('.menu-toggle'),
        mainMenu = $('.navbar-nav');

    menuToggle.on('click', function () {
        if (menuToggle.hasClass('is-active')) {
            mainMenu.removeClass('menu-open');
        } else {
            mainMenu.addClass('menu-open');
        }
    });

    /* -------------------- Back To Top -------------------- */

    $(document).on('click', '.back-to-top', function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    });

    let lastScrollTop = "";

    $(window).on("scroll", function() {
        /*---------------------------------------
        sticky menu activation && Sticky Icon Bar
        -----------------------------------------*/
        let st = $(this).scrollTop(),
            mainMenuTop = $(".navbar-area");

        if ($(window).scrollTop() >= 1) {
            mainMenuTop.addClass('navbar-area-fixed');
        }
        else {
            mainMenuTop.removeClass('navbar-area-fixed');
        }

        lastScrollTop = st;

        let ScrollTop = $('.back-to-top');

        if ($(window).scrollTop() > 1000) {
            ScrollTop.fadeIn(1000);
        } else {
            ScrollTop.fadeOut(1000);
        }
    });

    let backToTop = $('.back-to-top')
    backToTop.fadeOut()

    /* -------------------- Interactive Maps -------------------- */
    let paths = $('.interactive-path')

    paths.hover(function () {
        $('#interactive-map__district').text($(this).data('district'))
        $('#interactive-map__votes').text($(this).data('votes'))
    })

    /* -------------------- Counter Up -------------------- */
    let countdown = $(".countdown");
    if (countdown.length) {
        countdown.counterUp({
            delay: 10,
            time: 1000
        });
    }

    /* -------------------- Sidebar Menu -------------------- */
    let bodyOverlay = $('#body-overlay'),
        sidebarMenu = $('#sidebar-menu');

    $(document).on('click', '.sidebar-menu-close', function (e) {
        e.preventDefault();
        bodyOverlay.removeClass('active');
        sidebarMenu.removeClass('active');
    });

    $(document).on('click', '#navigation-button', function (e) {
        e.preventDefault();
        sidebarMenu.addClass('active');
        bodyOverlay.addClass('active');
    });

    /* -------------------- Mobile Menu -------------------- */
    if ($(window).width() < 992) {
        $(".in-mobile").clone().appendTo(".sidebar-inner");
        $(".in-mobile ul li.menu-item-has-children").append('<i class="fas fa-chevron-right"></i>');
        $('<i class="fas fa-chevron-right"></i>').insertAfter("");
    }

    /* -------------------- Banner Slider -------------------- */
    let slider4 = $('.banner-slider-4')
    slider4.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplaySpeed: 10000,
        infinite: true,
        autoplay: true,
    });
    slider4.slickAnimation()

    /* -------------------- Twitter Widget -------------------- */
    // Twitter widget is loading.
    window.twttr = function (d, s, id) {
        var t, js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return; js = d.createElement(s); js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js, fjs);
        return window.twttr || (t = { _e: [], ready: function (f) { t._e.push(f) } });
    }(document, 'script', 'twitter-wjs');

    // When widget is ready, run masonry
    twttr.ready(function (twttr) {
        twttr.events.bind('loaded', function (event) {
            $('.masonry-row').masonry();
        });
    });
})
